/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Inter', sans-serif;
  
    color: #333;
  }
  /* .journey-with-securoak{
    margin: 30px 50px 0px 50px;
    margin-bottom: 40px;
  } */
  
  /* Title Style */
  .journey-with-securoak{
    /* margin: 30px 50px 0px 50px; */
    margin-bottom: 40px ;
    background: #EEF9FF
  }
  .journey-body{
    background: #EEF9FF !important;

  }
  /* Title Style */
  .journey-title {
    font-size: 34px;
    padding-top: 30px;
    text-align: center;
    color: #333;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    position: relative;
    animation: slideIn 0.7s ease forwards;
    opacity: 0;
    
  }
  
  /* Animation for Title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .unlock-potential{
    background: #ddf2ff7d;
  }
  /* .unlock-potential .container{
    background: ;
  } */
  /* Style for the card */
.governence-card {
  background-color: transparent; 
  border-radius: 8px;
  padding: 20px;
  border: none;
}
.content {
  flex: 1;
  max-width: 60%; 
  text-align: center;
}

.card-main {
  justify-content: space-between;
  padding: 8px;
  border-radius: 6px;
  /* height: 300px; */
}
.list-content {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  background: transparent;
  color: #333;
}
.card-image {
  flex: 1; 
  max-width: 100%; 
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-image img {
  border-radius: 5px;
  height: 220px;
  object-fit: cover;
  width: 80%;
}
.content h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #222; 
  text-align: left;
}



.content h4 {
 
  text-align: center;
}

@media (max-width: 768px) {
  .card-main {
    flex-direction: column; 
  }
  .list-content {
    padding: 0 !important;
    font-size: 15px;
    text-align: justify;
  }
}


@media (max-width: 425px) {


  .card-main {
    flex-direction: column !important;
  }
  .list-content {
    padding: 0 !important;
    font-size: 12px;
    text-align: justify;
  }
  .identity-page .content-section {
    max-width: 100%;
    padding: 55px 5px 5px 5px;
}


}
@media (max-width: 375px) {
  .card-image img {
   
    border-radius: 5px !important;
}
.card-main {
  flex-direction: column !important;
}
.list-content {
  padding: 0 !important;
  font-size: 19px;
  text-align: center;
}
.spantext{
  font-size: 19px !important;
}

}


.why-we-stand-out {
    padding: 30px 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background: #ffffff;
  
    h2 {
       
        color: #222;
        font-family: Inter,sans-serif;
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 20px;
        margin-top: 8px;
        position: relative;
        text-align: center;
    }
    
  
    .intro {
      font-size: 17px;
      color: #555;
      max-width: 800px;
      margin: 0 auto 2rem;
    }
  
    .features-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 10px;
      padding: 20px;
  
      .feature {
        background-color: #f9f9f9;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
  
        &:hover {
          transform: translateY(-10px);
        }
  
        .icon {
          font-size: 2.0rem;
          color: #1a73e8;
          margin-bottom: 0.5rem;
        }
  
        h3 {
          font-size: 1.0rem;
          color: #1a73e8;
          margin: 0.5rem 0;
        }
  
        p {
          font-size: 1rem;
          color: #555;
        }
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .why-we-stand-out {
      padding: 40px 10px;
  
      h2 {
        font-size: 2rem;
      }
  
      .intro {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 375px) {
    .why-we-stand-out .features-grid {
      
      padding: 0px; 
  }



  }
  @media (max-width: 320px) {
    .why-we-stand-out .features-grid {
      
      padding: 0px; 
  }



  }
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.review-section {
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #edf2fac7;
  font-family: Inter, sans-serif;
  width: 100%;
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  
  .desc {
    font-size: 1.3rem;
    color: #555;
    margin-top: 10px;
    text-align: center;
  }
  
  .stars-stars {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .testimonial {
    margin-top: 30px;
    font-style:normal;
    color: #555;
    font-size: 1rem;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .testimonial .quote {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 15px !important;
    position: relative;
    padding-left: 30px;
  }
  
  // .testimonial .quote:before {
  //   content: '"';
  //   font-size: 3rem;
  //   color: #f47721;
  //   position: absolute;
  //   top: -10px;
  //   left: 0;
  // }
  
  .testimonial .author {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  .testimonial .role {
    font-weight: normal;
    font-size: 1rem;
    color: #777;
  }
  
  .quote-content p{
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
  .stars-stars{
    margin-top: 10px;
  }
  .quote-content{
    padding: 20px;
  }

  .content {
    text-align: center;
    font-size: 13px;
    color: #000;
    margin-bottom: 15px;
  }

  .stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .num,
    .total {
      font-size: 30px;
      font-family: "Inter", sans-serif;
    }

    .yellow {
      svg {
        color: #f47721;
        font-size: 25px;
        margin-bottom: 5px;
      }
    }
  }

  .review-swiper {
    margin-top: 30px;
    margin-bottom: 30px;

    .main {
      background-color: #fff;
      height: 345px;
      padding: 20px;
      font-family: "Poppins", sans-serif;
      border-radius: 10px;
     
      .image-container {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
          border-radius: 50px;
        }

        .title {
          text-align: center;
          font-size: 20px;
          color: #333;
        }
      }

      .email {
        margin-bottom: 5px;
        font-family: "Poppins", sans-serif;

        a {
          text-decoration: none;
          text-align: justify;
          font-size: 16px;
          color: #000000a6;
        }
      }

      .stars-stars {
        display: inline-flex; /* Inline-flex to align within text-centered Col */
        justify-content: center; 
        gap: 5px;
        margin-bottom: 15px;
      }
      
      .stars-stars svg {
        color: #f47721 !important;
        font-size: 20px;
        justify-content: center; 
      }
      
      

      .content {
        text-align: start;
        font-size: 16px;
        color: #000000bd;
        margin-bottom: 14px;
        max-height: 400px;
        overflow-y: auto;
        max-width: 300px;
        padding-right: 5px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #8b8b8b;
        }
      }
    }
  }

  .read-more {
    cursor: pointer;
  }

  .custom-modal {
    .modal-dialog {
      max-width: 800px; 
    }
  }
  
  .large-modal {
    .modal-content {
      height: 800px; /* Fixed height for the entire modal */
      overflow: hidden; /* Prevent outer scrolling */
    }
  }
  
  
 
  
  .content-scroll {
    max-height: 500px; /* Set this to whatever height fits your design */
    overflow-y: scroll;  /* Enables vertical scrolling */
    padding-right: 10px; /* Space for scrollbar */
    margin-top: 10px; /* Space between title and content */
  }
  
  
  

  .modal-header {
    border-bottom: 2px solid #fff;
  }

  .modal-title {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 24px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  .modal-body {
    text-align: center;
    height: 300px !important;
    overflow: hidden;
    overflow-y: scroll;
  }

  .modal-review-image {
    max-width: 100%;
    border-radius: 15px;
    animation: zoomIn 0.5s;
  }

  .modal-review-text {
    font-size: 18px;
    margin: 20px 0;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .modal-close-button,
.btn-like {
  background: #085f8b; /* Background color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 50px; /* Rounded corners */
  padding: 10px 20px; /* Padding for size */
  transition: background 0.3s, transform 0.2s; /* Smooth transition for hover effects */
  cursor: pointer; /* Pointer cursor on hover */
}

.modal-close-button:hover,
.btn-like:hover {
  background: #0a9fbb; /* Change background color on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}


  .btn-like {
    position: relative;
  }

  .btn-like.animate {
    animation: shake 0.3s;
  }

  @keyframes shake {
    0% { transform: translate(0); }
    25% { transform: translate(-5px); }
    50% { transform: translate(5px); }
    75% { transform: translate(-5px); }
    100% { transform: translate(0); }
  }

  @keyframes zoomIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .modal-decorative {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Adjust spacing as needed */
  }

  .modal-icon {
    font-size: 24px; /* Adjust size as needed */
    color: #28a745; /* Change color to match your theme */
    animation: bounce 1s infinite; /* Simple bounce animation */
    margin-right: 10px; /* Space between icon and title */
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  a {
    text-decoration: none;

    .more-reviews {
      padding: 10px;
      border: none;
      width: 150px;
      border-radius: 5px;
      font-size: 16px;
      font-family: "Inter", sans-serif;
      background-color: #085f8b;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto;

      &:hover {
        color: #fff;
        background-color: #0a1435;
      }
    }
  }
}
.review-slider:hover {
  /* Disable scrolling on hover */
  overflow: hidden;
  pointer-events: all; /* Ensures the card can be interacted with */
}

.popup-para {

  text-align: start;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7411764706);
  
  
}
.main{
  cursor: pointer;
}
.popup-list{
  list-style-type: disc;
  color: #212529;
  font-size: 16px;
}


.summary {

  padding: 10px;
 
  border-radius: 8px; /* Rounded corners for softer look */
  
  max-width: 900px;
  margin: -23px auto 0;
  text-align: center;
  font-size: 1.1rem;
  color: #555;
  margin-top: 20px;
}

.summary p {
  margin: 0;
  font-weight: 400;
  line-height: 1.6;
  font-size: 18px;
}

.summary p strong {
  color: #000; /* Emphasize key parts in black */
  font-weight: 600;
}



@media (min-width: 320px) {

  .review-section .testimonial .quote {
    font-size: 15px;
    
    padding-left: 0px;
}
.summary p {
 
  font-size: 15px;
}
}
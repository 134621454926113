.application-integration {
    padding: 0 20px;  
    padding-bottom: 0 !important;
     background-color: #ffff;
    font-family: 'Inter', sans-serif;
  }
  
  .keys-container {
    max-width: 1000px;
    margin: auto;
  }
  
  .integration-title {
    font-size: 34px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  
   
   
  }
  
  .integration-description,
  .integration-details {
    font-size: 15px;
    line-height: 1.8;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;

    max-width: 900px;
  }
  
  .integration-description strong {
    color: #007bb5;
  }





  .keys-section {
    padding: 10px 0 50px 0px;
   
    text-align: center;
  }
  
  .keys-section-title {
    font-size: 34px;
    
    color: #333;
    margin-bottom: 20px;
  }
  
  .keys-section-description {
    font-size: 1.2rem;
    color: #555;
    
    max-width: 800px;
    margin: 13px auto;
  }
  
  .keys-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
  }
  
  .keys-benefit-item {
    display: flex;
    align-items: center;
    justify-content: center;
   
    
   
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .keys-benefit-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .keys-benefit-image {
    width: 80px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height to keep the image square */
    object-fit: cover; /* Ensures the image fits well without distorting */
    margin-bottom: 15px; /* Adds spacing between the image and text */
    transition: transform 0.3s ease-in-out; /* Adds a smooth hover effect */
  }
  
  .keys-benefit-image:hover {
    transform: scale(1.1); /* Slightly enlarges the image on hover */
  }
  
  .keys-benefit-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .keys-benefit-content {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .keys-benefit-icon {
    width: 60px;  /* Adjust size as needed */
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 50%;
    padding: 10px;
  }
  
  .keys-benefit-text {
    font-size: 1rem;
    color: #666;
    max-width: 730px;
    text-align: left;
  }
  
  .keys-benefit-text strong {
    font-weight: bold;
    color: #333;
  }
  .application-integration img{
    height: 400px;
  }
  
  @media (max-width: 768px) {
    .integration-title {
      font-size: 2rem;
    }
  
    .integration-description,
    .integration-details {
      font-size: 1rem;
    }
  }
  .journey-with-securoak{
    /* margin: 30px 50px 0px 50px; */
    margin-bottom: 40px;
    background: #ddf2ff7d;
  }
  
  /* Title Style */
  .journey-title {
    font-size: 34px;
    padding-top: 30px;
    text-align: center;
    color: #333;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    position: relative;
    animation: slideIn 0.7s ease forwards;
    opacity: 0;
    
  }
  
  /* Animation for Title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .unlock-potential{
    background: #ddf2ff7d;
  }
  /* .unlock-potential .container{
    background: ;
  } */
  /* Style for the card */
.governence-card {
  background-color: transparent; 
  border-radius: 8px;
  padding: 20px;
  border: none;
}
.content {
  flex: 1;
  max-width: 60%; 
  text-align: center;
}

/* .card-main {
  justify-content: space-between;
  padding: 15px;
  border-radius: 6px;
  height: 300px;
}
.list-content {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  background: transparent;
  color: #333;
}
.card-image {
  flex: 1; 
  max-width: 40%; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #222; 
  text-align: left;
}


.unlock-img {
  width: 400px;
  height: 250px;
  border-radius: 20px;
  padding: 10px;
  object-fit: cover; 
} */


/* @media (max-width: 768px) {
  .card-main {
    flex-direction: column; 
  }
} */


@media (max-width: 425px) {
  .governence-card {
    
    padding: 0px !important;
   
  }
  .card-body {
   padding: 0;
}

}


 @media (max-width: 375px) {
  .why-securoak-description {
    font-size: 15px;
    color: #555;
    max-width: 700px;
    margin: 0 auto;
  }
  .title-below-para {
    font-size: 18px;
    padding: 10px;
    text-align: center;
}
.governence-card {
 
  padding: 0px !important;
 
}
.card-body {
 padding: 0px !important;
}
.why-securoak-section {
  padding:10px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}
.journey-title {
  font-size: 23px;
  
}
.keys-benefit-text {
  font-size: 17px;
  
  text-align: center;
}
.benefit-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: 0 !important;
  padding: 15px;
}
 }


 @media (max-width: 320px) {
  .keys-benefit-text {
    font-size: 16px;
    
    text-align: center;
  }
  .benefit-image {
    width: 40px;
    height: 40px;
   
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
   padding-right: 0 !important;
   padding: 10px;
}

 }
/* Procurement section styles */
.procurement {
  
    padding: 40px 30px;
  
    background-color: #e2e8f0; 
    max-width: 1200px; /* Limit width for better readability */
    margin: 30px auto; /* Centered with space around */
    font-family: 'Inter', sans-serif; /* Inter font for modern look */
  }
  
  /* Title style */
  .procurement-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2c3e50; /* Darker text for emphasis */
  }
  
  /* Text styling */
  .procurement-text {
    font-size: 16px;
    line-height: 1.6;
    color: #34495e; /* Slightly lighter dark text for readability */
    text-align: center; /* Justified text for cleaner alignment */
    max-width: 900px; /* Ensure the text doesn’t stretch too wide */
    margin: 0 auto; /* Center the paragraph */
  }
  
  /* Add a hover effect for the section when interacting (optional) */
  .procurement:hover {
    background-color: #e2e8f0; /* Slightly darker on hover */
    transition: background-color 0.3s ease;
  }
  


  /* Key Offerings Section */
.key-offerings {
    background-color: #ffffff; /* Clean white background */
    padding: 40px 30px;
    border-radius: 8px; /* Rounded corners for a soft feel */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    max-width:97%; /* Limit width for better readability */
    margin: 40px auto; /* Center the section with space around */
    font-family: 'Inter', sans-serif; /* Modern font family */
  }
  
  /* Title Styling */
  .key-offerings-title {
    font-size: 32px;
    font-weight: 700;
    color: #2c3e50; /* Dark color for readability */
    margin-bottom: 20px;
    text-align: center; /* Center the title */
  }
  
  /* Individual Offering Item */
  .key-offering-item {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for each item */
    border-radius: 8px; /* Rounded corners for each item */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  }
  
  /* Offering Title */
  .offering-title {
    font-size: 24px;
    font-weight: 600;
    color: #34495e; /* Slightly lighter color for subheading */
    margin-bottom: 10px;
  }
  
  /* Offering List Styling */
  .offering-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .offering-list li {
    font-size: 16px;
    line-height: 1.6;
    color: #34495e;
    margin-bottom: 15px;
  }
  
  .offering-list li strong {
    font-weight: 700;
    color: #1abc9c; /* Highlighting key terms with a fresh color */
  }
  
  /* Hover Effect for Items */
  .key-offering-item:hover {
    background-color: #ecf3f6; /* Light hover effect */
    transition: background-color 0.3s ease;
  }

.description4{
  font-size: 17px;
  margin-bottom: 70px !important;
}





/* Section Style */
.software-voip-solutions {
    background-color: #f7f9fb; /* Light background color */
    padding: 30px 20px;
   
    max-width: 100%;
    margin: 0px auto;
    font-family: 'Inter', sans-serif;
  }
  
  .section-title {
    text-align: center;
    font-size: 32px;
   
    color: #333;
   
   
    letter-spacing: 1px;
  }
  
  .sub-title {
    font-size: 28px;
    color: #333;
 
    margin-bottom: 20px;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Card Layout */
  .licensing-cards, .voip-cards {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .licensing-card, .voip-card {
    background: #ffffff;
   
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: calc(33.33% - 30px);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 0.8s forwards;
  }
  
  




  
  /* Animation for fading and sliding */
  .licensing-card:nth-child(1), .voip-card:nth-child(1) {
    animation-delay: 0.2s;
  }
  .licensing-card:nth-child(2), .voip-card:nth-child(2) {
    animation-delay: 0.4s;
  }
  .licensing-card:nth-child(3), .voip-card:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .licensing-card:hover, .voip-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Card Icons */
  .card-icon {
    font-size: 40px;
    margin-bottom: 20px;
    color: #3498db; /* Icon color */
  }
  
  /* Card Titles */
  h4 {
    font-size: 22px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  /* Card Text */
  p {
    font-size: 16px;
    color: #34495e;
    line-height: 1.6;
  }
  



  .cybersecurity-integration {
    background-color: #f4f7fa; /* Slightly lighter background */
    padding: 80px 0; /* Increased padding for better spacing */
  }
  
  .cybersecurity-container {
    width: 90%;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    font-size: 32px; /* Larger font size */
    color: #333;
    margin-bottom: 30px;
  }
  
  .cybersecurity-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
  }
  
  .cybersecurity-feature {
    background-color: white;
    padding: 40px 30px; /* Added padding for more spacious content */
   
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1 1 calc(33% - 20px); /* Flex for responsive design */
    min-width: 280px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cybersecurity-feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .feature-icon {
    font-size: 50px; /* Increased icon size */
    color: #3498db; /* Icon color */
   
  }
  
.cyber-head {
    font-size: 22px; /* Larger heading */
   
    color: #34495e;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1rem;
    color: #7f8c8d;
    line-height: 1.6;
    margin-bottom: 0;
  }
  
















  /* Responsive Design */
  @media (max-width: 1024px) {
    .licensing-card, .voip-card {
      width: calc(50% - 30px); /* Two cards per row on tablets */
    }
  }
  
  @media (max-width: 768px) {
    .licensing-card, .voip-card {
      width: 100%; /* One card per row on mobile */
    }
  
    .section-title {
      font-size: 32px;
    }
  
    .sub-title {
      font-size: 24px;
    }
  
    h4 {
      font-size: 20px;
    }
  }
  
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .key-offerings {
      padding: 20px 15px;
    }
  
    .key-offerings-title {
      font-size: 28px;
    }
  
    .offering-title {
      font-size: 20px;
    }
  
    .offering-list li {
      font-size: 14px;
    }
    .why-partner-description {
      font-size: 15px;
     
  }
  }
  @media (max-width: 375px) {

    .procurement-text {
      font-size: 18px;
      color: #000;
      
  }
  .procurement {
 padding: 10px;
  margin: 0;
}
.section-title {
 
  line-height: 29px;
}
.section-title {
  font-size: 30px !important;
}
  }

 
  
  
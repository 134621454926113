.managedservice-description {
    
    padding: 30px;
    border-radius: 12px;
    
    text-align: center;
    max-width: 800px;
    margin: 20px auto;
  }
  
  .managedservice-title {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .managedservice-text {
    font-size: 15px;
    text-align: center;
    color: #333;
    line-height: 1.6;
  }
  .service-title{
    margin-left: 30px;
  }
  .right-content ul{
    height: 200px;
  }
.section-description2{
  max-width: 900px;
  margin-bottom: 0 !important;
}
 /* Wrapper for the service image and content */
.service-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align top for image and content */
  margin-top: 20px;
}

/* Left column: image */
.service-image {
  flex: 1; /* Takes up 50% of the width */
  max-width: 400px;
  display: flex;
  justify-content: center;
}
.service-image img{
height: 300px;
width: 600px;
object-fit: cover;

}
/* Right column: content */
.service-content {
  flex: 2; /* Takes up 50% of the width */
  padding-left: 50px; /* Space between image and content */

}
.service-content ul{
  height: 180px;
  max-width: 500px;
}
.service-content h3{
  margin-left: 30px;

}
.managed-container{
  padding: 12px 30px 30px 30px;
  padding: 12px 30px 30px 30px;
  display: flex; /* Ensures align-items works */
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers items horizontally */

}
/* Description below the buttons */
.service-navigation {
  margin-top: 20px; /* Space between content and buttons */
  display: flex;
  margin-left: 33px;
  width: 100%;
}
 .prev-btn{
  margin-right: 20px;
}
/* .next-btn{
  margin-right: -20xp;
}  */
.prev-btn, .next-btn {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  gap: 20px;
 
}
.toggle-img{
  height: 300px;
  object-fit: cover;
  width: 100%;
}

.prev-btn:hover, .next-btn:hover {
  background-color: #0056b3;
}
.why-managed-it-img img{
height: 284px;
object-fit: cover;
}

.why-partner-description2{
  margin-bottom: 70px;
}
@media (max-width: 1024px) {

  .managed-container{
   
 
  
  }
  .right-content ul{
    height: 200px !important;
  }

}
@media (max-width:768px) {

.service-title{
  font-size: 15px;
  
}
.service-image img {
 
  margin-right: -130px;
}
.service-content {
 
  padding-left: 0px;
}

}

@media (max-width:425px) {
  .managed-container {
   margin: 10px;
   padding: 60px;
  }
 
 .prev-btn, .next-btn {
  margin: 0 !important;
 }
 .prev-btn{
  margin-right: 5px !important;
 }
 .service-content ul {
  height: 220px;
  max-width: 100% !important;
}
.service-content {
  padding: 0;
}
.why-partner-image2 img {
 
  padding: 10px;
}
.section-title {
  font-size: 20px !important;
}

}
@media (max-width:375px) {

  .managedservice-text {
    font-size: 19px;
    
  }
  .service-content ul {
    
    font-size: 17px;
    
}
}


@media (max-width:320px) {
  
   .prev-btn, .next-btn {
    margin: 0 !important;
   }
   .prev-btn{
    margin-right: 5px !important;
    margin-left: 43px !important;
   }
   .service-content ul {
    padding-left: 67px;
    height: 220px;
    max-width: 100% !important;
}
  .service-content {
    padding: 0;
  }
  .why-partner-image2 img {
   
    padding: 10px;
  }
  .service-content h3 {
   text-align: center;
}
.section-title {
  font-size: 20px !important;
}

.managedservice-text {
  font-size: 18px;
  
}
}
@import "../../../main.scss";
.cloud_full {
  padding: 70px 0px 70px 0px;

  background-color: #f9f9f9;
  .cloud_cnt {
    .title {
      font-family: Inter, sans-serif;

      font-size: 14px;
      font-weight: 700;
      background-image: linear-gradient(
        to right top,
        #0e30ed,
        #004ff1,
        #0065ef,
        #0078e9,
        #2788e0
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
    .desc {
      font-family: Inter, sans-serif;
    position: relative;
    font-size: 29px;
    font-weight: 500;
    line-height: 33px;
    margin-top: 10px;
    margin-bottom: 29px;
    color: #222;
    }
//     .desc::before {
//     position: absolute;
//     content: "";
//     height: 2px;
//     width: 51px;
//     bottom: -7px;
//     left: 7%;
//     transform: translateX(-50%);
//     background: #0513f1;
// }

// .desc::after {
//     position: absolute;
//     content: "";
//     height: 9px;
//     width: 9px;
//     border-radius: 50%;
//     background: #fff;
//     border: 2px solid #0513f1;
//     box-shadow: 0 0 0 5px #fff;
//     bottom: -10px;
//     left: 1%;
//     transform: translateX(-50%);
// }
    .sub_desc {
      font-family: Inter, sans-serif;

      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }
  }
  .total_list {
    .list {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      .para {
        display: flex;
        align-items: baseline;
        gap: 10px;
        margin-top: 10px;
        .tick {
          font-size: 7px;
          svg {
            color: #fff;
            background-color: #197fa1;
            height: 2px;
            width: 2px;
            font-size: 5px;
            border-radius: 20px;
            margin-bottom: 3px;
          }
        }
        .titles {
          color: #666;
          font-family: "Poppins", sans-serif;

          font-size: 15px;
    width: 13rem;
          border-radius: 10px;
        }
      }
    }
  }

  .learn {
    height: 40px;

    margin-top: 40px;
    border: none;
    border-radius: 30px;
    padding: 10px;
    width: 25%;
    background: linear-gradient(
      90deg,
      rgba(5, 17, 242, 1) 0%,
      rgba(6, 21, 241, 1) 30%,
      rgba(39, 136, 224, 1) 63%,
      rgba(49, 173, 219, 1) 87%,
      rgba(54, 187, 217, 1) 100%
    );
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 13px;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      color: #fff;
      .arrow_right {
        svg {
          color: #fff;
          font-size: 13px;
          margin-bottom: 3px;
        }
      }
    }
    &:hover {
      background: #fff; /* Change background on hover */
      color: #0059ff; /* Change text color on hover */
      border: 2px solid #0059ff; /* Add border on hover */

      a {
          color: #0059ff; /* Change link color on hover */
      }

      .arrow_right {
          svg {
              color: #0059ff; /* Change SVG color on hover */
          }
      }
  }
  }
  .image {
    overflow: hidden;
    position: relative;
        height: 31rem;
    img {
      width: 97%;
      transition: transform 0.5s ease;
      // border-radius: 10px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .developement_full .api_management .desc {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .cloud_full .total_list .list .para .titles {
    font-size: 14px;
  }
  .cloud_full .total_list .list .para {
    gap: 5px;
  }
  .cloud_full .total_list .list .para .tick svg {
    height: 16px;
    width: 16px;
  }
  .cloud_full .learn {
    margin-top: 5px;
    height: auto;
    padding: 10px 20px;
    width: 25%;
    font-size: 12px;
  }
}

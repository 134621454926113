
@import "../main.scss";
.about-page {
  font-family: Poppins, sans-serif !important;
    .about-image-container {
      position: relative; // Make sure the container can position its children
      text-align: center; // Center text horizontally
      color: rgb(238, 0, 0); // Set text color to white for contrast (adjust as needed)
  
      .about-page-image {
        width: 100%;  // Ensure the image covers the full width of its container
        height: auto; // Maintain aspect ratio
        filter: brightness(50%); // Apply 50% brightness filter
        margin-top: 70px;
      }
  
      .overlay-text {
        position: absolute; // Position text absolutely within the container
       
        transform: translate(-50%, -50%); // Offset the text by half its size to perfectly center it
        width: 100%; // Make sure the text container spans the full width
        padding: 20px; // Optional: add padding around the text
        box-sizing: border-box; // Ensure padding is included in the width calculation
        text-align: center; 
      }
    }
  
    ul {
      list-style-type: disc;
      margin-left: 20px;
  margin-top: 1rem;
 margin-bottom: -5rem;
     ::marker {
    color: #000000f1; 
  }
    }
   
  }
  .about-page {
    // General styles
    .about-image-container {
      position: relative;
      text-align: center;
      color: white; // Adjust based on image contrast
  
      .about-page-image {
        width: 100%;
        height: 400px;
        filter: brightness(50%);
        object-fit: cover; // Ensure the image covers the container without distortion
      }
  
      .overlay-text {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        color: white; // Adjust based on image contrast
  
        h1 {
          font-size: 2.5rem;
          margin-bottom: 0.5rem;
        }
  
        p {
          font-size: 1.0rem;
          margin: 0;
          color: #fff;
        }
      }
    }
  

    .about_image img {
    width: 100%;
    padding: 70px 0px;
    object-fit: cover;
    height: 706px;
    border-radius: 38px;
    border-radius: 8px;
}
    .abt_desc {
      .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: bold;
      }
  
      .desc {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
  
      .passage {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
  
      .two_blocks {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;
  
        .grt, .exp {
          flex: 1;
          padding: 1rem;
          border: 1px solid #ddd;
          border-radius: 8px;
          text-align: center;
  
          .title {
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
          }
  
          .desc {
            font-size: 1rem;
          }
        }
      }
  
      .learn {
        margin-top: 2rem;
        text-align: center;
  
        a {
          text-decoration: none;
          color: #000;
          font-size: 1.2rem;
          display: inline-flex;
          align-items: center;
          background-color: #f1f1f1;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #ddd;
          }
  
          .arrow_right {
            margin-left: 0.5rem;
            display: flex;
            align-items: center;
  
            svg {
              font-size: 1.2rem;
            }
          }
        }
      }
    }


    .strategy-section {
      padding: 3rem; /* Increased padding for a spacious feel */
      background-color: #f9f9f9; /* Soft gray background for contrast */
      border-radius: 10px; /* Rounded corners for the section */
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for the section */
    
      .strategy-header {
        text-align: center;
        margin-bottom: 2rem;
    
        h2 {
          font-size: 2.5rem; /* Larger size for prominence */
          color: #2c3e50; /* Darker color for headings */
          margin-bottom: 0.5rem; /* Space below heading */
        }
    
        p {
          font-size: 1.2rem; /* Larger paragraph font */
          color: #7f8c8d; /* Softer gray color for the paragraph */
          max-width: 800px;
          margin: 0 auto;
        }
      }
    }
    
    .strategy-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Responsive grid layout */
      gap: 2rem; /* Space between cards */
    }
    
    .card {
      background: #ffffff; /* White background for cards */
      border: 1px solid #ddd; /* Light gray border for definition */
      border-radius: 10px; /* Rounded corners */
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
      padding: 2rem; /* Inner padding */
      transition: transform 0.3s, box-shadow 0.3s; /* Animation for hover effect */
      height: 480px;
      border-left: 3px solid #2980b9;;;
    
      h3 {
        font-size: 1.6rem; /* Consistent heading size */
        margin-bottom: 1rem; /* Space below heading */
        color: #000000d8; /* Darker color for headings */
        text-align: center;
      }
    
      ul {
        list-style-type: none; /* Remove bullet points */
        padding: 0; /* Remove padding */
    
        li {
          margin-bottom: 0.75rem; /* Space between list items */
          color: #555; /* Dark gray for list items */
    
          strong {
            color: #0c0c0cd0; /* Blue color for strong emphasis */
          }
        }
      }
    
      /* Hover effects */
      &:hover {
        transform: translateY(-2px); /* Slight lift effect on hover */
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
      }
    }
    
    

























  
    // Responsive styles
    @media (max-width: 768px) {
      .about-page-image {
        height: auto;
      }
  
      .overlay-text h1 {
        font-size: 2rem;
      }
  
      .overlay-text p {
        font-size: 1rem;
      }
  
      .abt_desc {
        .title {
          font-size: 1.5rem;
        }
  
        .desc {
          font-size: 1.2rem;
        }
  
        .passage {
          font-size: 0.9rem;
        }
  
        .two_blocks {
          flex-direction: column;
          gap: 1rem;
  
          .grt, .exp {
            width: 100%;
          }
        }
      }
    }
  
    @media (max-width: 576px) {
      .overlay-text h1 {
        font-size: 1.5rem;
      }
  
      .overlay-text p {
        font-size: 0.9rem;
      }
  
      .abt_desc {
        .title {
          font-size: 1.25rem;
        }
  
        .desc {
         font-size: 24px !important;
         line-height: 31px !important;
        }
  
        .passage {
          font-size: 0.9rem;
        }
  
        .two_blocks {
          .grt, .exp {
            padding: 0.5rem;
          }
        }
      }
    }
  }
  
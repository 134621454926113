.identity-governance-container {
    padding: 50px 20px 10px 20px;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
 
  }
  
  .identity-governance-title {
    font-size: 2rem;
    font-weight: 600;
    color: #003366;
    margin-bottom: 30px;
  }
  
  .identity-governance-description {
    font-size: 16px;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: center;
  }
  



  .why-partner-section {
    padding: 40px 90px;
    max-width: 100%;
   
    text-align: center;
       
        background: #E5F3FB;
  }
  
  .why-partner-top {
    margin-bottom: 30px;
  }
  
  .why-partner-title {
    font-size: 32px;
  
    color: #333;
    margin-bottom: 10px;
  }
  
  .why-partner-description {
    font-size: 1.1em;
    color: #555;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .why-partner-content {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  
  .why-partner-image {
    flex: 1;
    padding-right: 20px;
  }
  .why-partner-image img {
    height: 350px;
    margin-top: -52px;
    object-fit: cover;
    width: 100%;
}
  .image {
    width: 100%;
    height: 355px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .why-partner-features {
    flex: 2;
  }
  
  .partner-features-list {
    list-style-type: none;
    padding: 0;
  }
  
  .partner-features-list li {
    font-size: 1em;
    color: #444;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
  }
  
  .partner-features-list li::before {
    content: "•";
    color: #333;
    position: absolute;
    left: 0;
    font-size: 1.2em;
    top: 2px;
  }
  

  .securoak-benefit {
    background-color: #f5f8fa;
    padding: 20px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 800px;
    margin: 30px auto;
    font-family: 'Inter', sans-serif;
  }
  
  .securoak-benefit p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
  }
  
  .securoak-benefit strong {
    font-size: 1.3rem;
    color: #003366ee;
  }
  
  .securoak-benefit .highlight {
    color: #1d72b8;
    font-weight: bold;
    text-decoration: underline;
  }
  


  
  .journey-with-securoak{
    /* margin: 30px 50px 0px 50px; */
    margin-bottom: 40px;
    background: #ddf2ff7d;
  }
  
  /* Title Style */
  .journey-title {
    font-size: 34px;
    padding-top: 30px;
    text-align: center;
    color: #333;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    position: relative;
    animation: slideIn 0.7s ease forwards;
    opacity: 0;
    
  }
  
  /* Animation for Title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .unlock-potential{
    background: #ddf2ff7d;
  }
  /* .unlock-potential .container{
    background: ;
  } */
  /* Style for the card */
.governence-card {
  background-color: transparent; 
  border-radius: 8px;
  padding: 20px;
  border: none;
}
.content {
  flex: 1;
  max-width: 60%; 
  text-align: center;
}

/* .card-main {
  justify-content: space-between;
  padding: 15px;
  border-radius: 6px;
  height: 300px;
}
.list-content {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  background: transparent;
  color: #333;
}
.card-image {
  flex: 1; 
  max-width: 40%; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #222; 
  text-align: left;
}


.unlock-img {
  width: 400px;
  height: 250px;
  border-radius: 20px;
  padding: 10px;
  object-fit: cover; 
} */

@media (max-width: 768px) {
  .identity-governance-title {
    font-size: 1.6rem;
  }

  .identity-governance-description {
    font-size: 19px;
  }
  .card-main {
    flex-direction: column; 
  }
  .why-partner-image img{
    width: 100%;
    height: 250px;
    object-fit: cover;

  }
}
@media (max-width: 425px) {
  .why-partner-image img{
    width: 100%;
    height: 250px;
    object-fit: cover;

  }
  .why-partner-section {
    padding: 10px 10px;
    max-width: 100%;
    text-align: center;
    background: #E5F3FB;
}
  
} 

@media (max-width: 375px) {
  .card-main {
   padding: 0;
   padding-right: 0;
}
.why-partner-image img {
 padding: 10px;
}
.partner-features-list li {
  font-size: 20px;
  
}
.why-partner-description {
  font-size: 19px !important;
}
} 
@media (max-width: 320px) {
  .section-title {
    font-size: 22px;
}
.partner-features-list li {
  font-size: 18px;
  
}
} 
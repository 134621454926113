*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.contact-container{
    font-family: Inter, sans-serif;
   
   padding: 60px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
   
}
.name-fields {
    display: flex;
    gap: 20px; /* Space between First Name and Last Name */
}

/* Adjust the size of the input fields to fit within the same line */
.name-fields .contact-inputs {
    flex: 1;
}
.contact-row{
  padding: 50px;
}
.contact-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
 padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease; 
   
   
}
.contact-left-title h2{
    font-weight: 400;
    font-size: 34px;   
    color: #333 !important;
}
.contact-right-title h2{
    font-size: 29px;
    color: #333 !important;
    text-align: center;
    margin-top: 38px;
}
.contact-left-title hr {
    width: 200px; /* Width of the line */
    border: none; /* Remove default border */
    height: 2px; /* Height of the line */
    background-color: gray;

}


.contact-inputs {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}
.contact-left textarea{
    height: 100px;
    padding-top: 15px;
    border-radius: 10px;

}
.contact-left input:focus,
.contact-left textarea:focus {
    border: 1px solid #007bff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}
.contact-inputs::placeholder{
    color: #a9a9a9;
}


/* .contact-left {
    width: 40%;
} */

/* .contact-right {
    width: 40%;
} */

.contact-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  display: inline-block; /* Keeps the button in place */
  vertical-align: middle; /* Ensures alignment if needed */
}
.contact-right .round-icon{
    height: 70px;
    min-width: 70px;
    max-width: 70px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    background: #fff;
    text-align: center;
    line-height: 70px;
    font-size: 30px;
    color: #007bff;
    display: inline-block;
    margin-bottom: 10px;

}
.rightpara {
 
  font-size: 1.1rem;
  padding: 30px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.8823529412);
  font-family: Inter, sans-serif;
}
.contact-item {
    display: flex;
    align-items: center; /* Vertically centers the icon and text */
    gap: 10px; /* Space between the icon and the text */
}

.round-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Set width for the icon container */
    height: 30px; /* Set height for the icon container */
    background: #f0f0f0; /* Optional background */
    border-radius: 50%; /* Round icon container */
    font-size: 16px; /* Icon size */
}

.identity-image-container {
    position: relative;
    text-align: center;
    color: white;
    .identity-page-image {
        width: 100%;
        height: 450px !important;
        filter: brightness(50%); 
        margin-top: 70px;
    }

    .overlay-text {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff !important;
        text-align: center;

        h1 {
            font-size: 2.5rem;
            font-weight: 700;
        }

        p {
            font-size: 1rem;
            margin-top: 0.5rem;
            color: #fff !important; 
        }
    }
}

.banner-icon{
    font-size: 10px;
    margin: 5px;
  }
  button:disabled {
    opacity: 0.5;       /* Optional: Disabled state style */
    cursor: not-allowed;
  }
  /* ReCAPTCHA container styling */
/* Container for the reCAPTCHA widget */
.recaptcha-container {
    display: inline-block;        /* Keep the box inline with other elements */
    transform: scale(0.8);        /* Scale down to 80% of the original size */
    transform-origin: 0 0;        /* Scale from top-left corner */
                  /* Add some margin for spacing */
  }
  
  /* Adjust the height of the container to prevent clipping */
  .recaptcha-container iframe {
    height: 80px;                 /* Adjust height according to scaling factor */
    
  }
  
  .rightpara{
   
      font-size: 1.1rem;
        text-align: justify;
        color: rgba(0, 0, 0, 0.8823529412);
        font-family: Inter, sans-serif;
       
    }
  

  .checkbox{
    margin: 5px;
    margin-bottom: 10px;
  }
  .note{
    font-size: 12px;
    font-weight: bold;
  }





.map-container {
  padding: 40px 50px;
  font-family: Arial, sans-serif;
  background: #F5F5F5;
}

.location-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.location-btn {
  margin: 0 10px;
  padding: 10px 25px;
  margin-bottom: 20px;
  background-color: #FAFAFA;
  border: 1px solid #ccc;
  color: #344050;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: 1px;
}

.location-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .location-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .location-icon {
    font-size: 24px;
    color: #007bff;
  }
  
  .location-address {
    font-size: 16px;
 
    text-align: left;
    color: #555;
  }
  .address-column {
    display: flex;
    flex-direction: column;
    text-align: left;

    padding: 20px;
   
    border-radius: 8px;
   
 
  }
  .location-address {
    text-align: left; 
    color: #344054;   
    font-size: 18px; 
    font-weight: 400; 
    width: 100%;     
    max-width: 300px; 
    margin: 0;       
}

  .get-directions-btn:hover {
    background-color: #0056b3;
  }
  
  .get-directions-btn i {
    font-size: 18px;
  }  
.location-btn.active,
.location-btn:hover {
  background-color: #007bff;
  color: #fff;
}

.location-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}
.email-container {
    display: flex;
    align-items: center;
    gap: 10px; 
    background-color: #f4f4f4;
    margin-top: 20px;
    border-radius: 5px;
   
  }
  
  .email-icon {
    font-size: 20px;
    color: #007bff; /* Icon color */
  }
  
  .location-email {
    font-size: 15px;
    font-weight: 0 !important;
   cursor: pointer;
    margin: 0;
    text-decoration: none;
    font-family: Inter, sans-serif;
  }
  
  .location-email:hover {
    color: #007bff; /* Change color on hover */
    text-decoration: underline; /* Underline on hover */
  }
/* .map-column {
    flex: 0 0 60%; 
    padding-right: 20px; 
  }
  
  .address-column {
    flex: 0 0 40%; 
    padding-left: 20px; 
  } */


.map-column iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.address-column h3 {
  margin-bottom: 10px;
  font-size: 28px;
  color: #101828;
  font-weight: 600;
  text-align: left;
}


.our-location{
    text-align: center;
}



@media (max-width: 768px) {

  .contact-row {
    padding: 2px;
  }

}



@media (max-width: 425px) {

  .contact-row {
    padding: 2px;
  }
  .map-container {
    padding: 5px;
    
  }
   button:not(:disabled) {
    
    margin: 2px;
    
}
/* [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  margin: 5px 0;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  right: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  top: -20p;
  
} */
}





@media (max-width: 375px) {

  .contact-row {
    padding: 2px;
  }
  .map-container {
    padding: 5px;
    
  }
   button:not(:disabled) {
    
    margin: 2px;
    
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  margin: 5px 0;
}

}


@media (max-width: 320px) {

  .contact-row {
    padding: 2px;
  }
  .map-container {
    padding: 5px;
    
  }
   /* button:not(:disabled) {
    
    margin: 2px;
    
} */
/* [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  margin: 5px 0;
} */
.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block; /* Keeps checkbox in place */
}
.contact-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  display: inline-block; /* Keeps the button in place */
  vertical-align: middle; /* Ensures alignment if needed */
}
}






.journey-with-securoak{
    /* margin: 30px 50px 0px 50px; */
    margin-bottom: 40px;
    background: #ddf2ff7d;
  }
  
  /* Title Style */
  .journey-title {
    font-size: 34px;
    padding-top: 30px;
    text-align: center;
    color: #333;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    position: relative;
    animation: slideIn 0.7s ease forwards;
    opacity: 0;
    
  }
 .partnericon3{
  padding-right: 10px;
  font-size: 18px;
 }
  
  /* Animation for Title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .unlock-potential{
    background: #ddf2ff7d;
  }
  /* .unlock-potential .container{
    background: ;
  } */
  /* Style for the card */
.governence-card {
  background-color: transparent; 
  border-radius: 8px;
  padding: 20px;
  border: none;
}
.content {
  flex: 1;
  max-width: 60%; 
  text-align: center;
}
/* 
.card-main {
  justify-content: space-between;
  padding: 15px;
  border-radius: 6px;
  height: 300px;
}
.list-content {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  background: transparent;
  color: #333;
}
.card-image {
  flex: 1; 
  max-width: 40%; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #222; 
  text-align: left;
}


.unlock-img {
  width: 400px;
  height: 250px;
  border-radius: 20px;
  padding: 10px;
  object-fit: cover; 
} */


@media (max-width: 1024px) {
  .list-content {
   
    padding: 10px 30px;
    
  }


}

@media (max-width: 768px) {
  .card-main {
    flex-direction: column; 
  }
  
 
    

}

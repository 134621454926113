.offering2{
    height: 240px;
    background-color: #fff;
}
.offering2 h3{
    text-align: center;

}
.offering2 p{
    text-align: center;
    max-width: 900px;
    padding-top: 10px;

}



@media (max-width: 768px) {
    .offering2 {
        height: 300px;
        background-color: #fff;
    }


}
@media (max-width: 425px) {
    .staffing-outsourcing .container {
        width: 100%;
       
       
    }
    .service-description p {
        font-size: 14px;
        text-align: left;
        padding: 5px;
    }
    .why-partner-with-us {
        
        padding: 10px;
       
    }
}

@media (max-width: 375px) {
    .service-description p {
        font-size: 20px;
        text-align: center;
        padding: 5px;
    }
    .offering2 {
        height: 400px;
        background-color: #fff;
    }
}


@media (max-width: 320px) {
    .cta h3 {
        font-size: 19px;
        padding-top: 10px;

    }
    .staffing-outsourcing .container {
        width: 100%;
       
       
    }
    .service-description p {
        font-size: 16px;
        text-align: left;
        padding: 5px;
    }
    .why-partner-with-us {
        
        padding: 10px;
       
    }
    .header_navebar .main_logo img {
        width: 60% !important;
        height: 40px;
        object-fit: contain;
    }

}

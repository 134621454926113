.upcoming-events {
  background: linear-gradient(to bottom, #f9f9f9, #ffffff);
  padding-bottom: 40px;

  .identity-image-container {
    position: relative;

    img.identity-page-image {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
      }

      p {
        font-size: 1rem;
        margin-top: 10px;
      }

      .banner-icon {
        margin: 0 5px;
      }
    }
  }

  .event-container {
    margin-top: 60px;
    margin-bottom: 40px;

    .event-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .event-col {
        margin-bottom: 20px;

        .event-card {
          display: flex;
          align-items: center;
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          padding: 20px;
          transition: transform 0.3s ease;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
          }

          .event-img {
            width: 100%;
            height: 400px;
            object-fit: contain;
            border-radius: 8px;
          }

          .event-text-col {
            padding-left: 20px;

            .event-title {
              font-size: 1.5rem;
              font-weight: bold;
              color: #333;
              margin-bottom: 30px;
            }

            p {
              margin: 10px 0;
             

              strong {
                font-weight: bold;
                color: #333;
              }

              i {
                color: #007bff;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {

  .upcoming-events .identity-image-container .overlay-text h1 {
    font-size: 20px;
    
}
  .upcoming-events .event-container .event-row .event-col .event-card .event-img {
    
    height: 341px;
    
  }
  .upcoming-events .event-container .event-row .event-col .event-card .event-text-col .event-title {

    margin-top: 20px;
}

}

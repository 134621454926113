@import "../../main.scss";

.Btn {
  width: 52px;
  height: 51px;
  background: linear-gradient(
    90deg,
    rgb(5, 17, 242) 0%,
    rgb(6, 21, 241) 30%,
    rgb(39, 136, 224) 63%,
    rgb(49, 173, 219) 87%,
    rgb(54, 187, 217) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  z-index: 1000;
  font-size: larger;
  font-weight: 500;
}

.arrow path {
  fill: white;
}

.Btn:hover .text {
  opacity: 1;
  transition-duration: 0.7s;
  font-size: 15px;
}

  .arrow {
    font-size: 80px !important;  /* Force the icon size with !important */
  }
  

.Btn:hover .arrow {
  animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

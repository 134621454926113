.why-securoak-section {
  padding: 20px 20px;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
      margin-bottom: 50px;
}

.why-securoak-top {
  margin-bottom: 30px;
}

.why-securoak-title {
  font-size: 34;

  color: #333;
  margin-bottom: 10px;
}

.why-securoak-description {
  font-size: 1.1em;
  color: #555;
  max-width: 700px;
  margin: 0 auto;
}

.why-securoak-content {
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.why-securoak-image {
  flex: 1;
  padding-right: 20px;
}

.why-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.why-securoak-features {
  flex: 2;
}

.features-list {
  list-style-type: none;
  padding: 0;
}

.features-list li {
  font-size: 1em;
  color: #444;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}

.features-list li::before {
  content: "•";
  color: #333;
  position: absolute;
  left: 0;
  font-size: 1.2em;
  top: 2px;
}
@media (max-width:1024px) {  

  .why-securoak-section {
    padding: 20px ;
    max-width: 100%;
   
  }
  .application-integration img {
    height: 451px;
}


}


@media (max-width:768px) {  

.why-securoak-image{
  display: none;
}

}
@media (max-width:425px) { 
  .why-securoak-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
}

@media (max-width:375px) {
  .features-list li {
    font-size: 18px;
    
    padding-left: 15px !important;
   
  }
  .why-securoak-description {
    font-size: 19px !important;
   
  }
  
}
@media (max-width:320px) { 
  .feature-heading {
    font-size: 18px !important;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  .why-securoak-description {
    font-size: 15px !important;
    color: #555;
    max-width: 700px;
    margin: 0 auto;
  }
  .features-list li {
    font-size: 18px;
    
    padding-left: 0px;
   
  }
  
}


.pam-section {
  
    padding: 40px;
    border-radius: 12px;
   background-color: #fff;
    font-family: 'Inter', sans-serif;
    max-width: 100%;
    
    line-height: 1.6;
  }
  
  .pam-intro{
    max-width: 900px;
    margin: 0 auto;
    
  }
  .pam-intro, .pam-details, .pam-conclusion, .pam-expertise {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  .privilage-para{
    max-width: 900px;
    margin: 0 auto;
  }
  .pam-intro .highlight {
    color: #1d72b8;
    font-weight: bold;
  }
  
  .pam-subtitle {
    font-size: 1.4rem;
    font-weight: 700;
    color: #004080;
    margin-top: 25px;
    margin-bottom: 15px;
    text-align: center;
  }
  
 
  .list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width);
    text-align: left !important;
}




.privilage-partner-with-securoak {
    padding: 60px 20px;
    background-color: #f9fbfc;
    font-family: 'Inter', sans-serif;
  }
  
  .privilage-partner-with-securoak-title {
    font-size: 34px;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
   
    letter-spacing: 1px;
  }
  
  .privilage-row{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
  }
  
  .img-col {
    flex: 1;
    text-align: center;
  }
  
  .section-image {
    max-width: 95%;
    height: 430px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .text-col {
    flex: 1;
  }
  
  .text-wrapper {
    max-width: 600px;
    margin: auto;
  }
  
  .text-wrapper p {
    font-size: 16px;
    line-height: 1.8;
    color: #333;
    margin-bottom: 20px;
    text-align: justify;
}
  
  .text-wrapper strong {
    color: #007bb5;
  }
  
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .img-col {
      margin-bottom: 20px;
    }
  
    .text-wrapper {
      max-width: 100%;
    }
  }
  .journey-with-securoak{
    /* margin: 30px 50px 0px 50px; */
    margin-bottom: 40px;
    background: #ddf2ff7d;
  }
  
  /* Title Style */
  .journey-title {
    font-size: 34px;
    padding-top: 30px;
    text-align: center;
    color: #333;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    position: relative;
    animation: slideIn 0.7s ease forwards;
    opacity: 0;
    
  }
  
  /* Animation for Title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .unlock-potential{
    background: #ddf2ff7d;
  }
  /* .unlock-potential .container{
    background: ;
  } */
  /* Style for the card */
.governence-card {
  background-color: transparent; 
  border-radius: 8px;
  padding: 20px;
  border: none;
}
.content {
  flex: 1;
  max-width: 60%; 
  text-align: center;
}

/* .card-main {
  justify-content: space-between;
  padding: 15px;
  border-radius: 6px;
  height: 300px;
}
.list-content {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  background: transparent;
  color: #333;
}
.card-image {
  flex: 1; 
  max-width: 40%; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #222; 
  text-align: left;
}


.unlock-img {
  width: 400px;
  height: 250px;
  border-radius: 20px;
  padding: 10px;
  object-fit: cover; 
}


@media (max-width: 768px) {
  .card-main {
    flex-direction: column; 
  }
} */


@media (max-width: 375px) {
  .pam-section {
    padding: 10px;
  
  }
  .section-image {
    max-width: 100%;
    height: 300px;
  
}
.btn {
  
  padding: 9px 29px !important;
  
}
.privilage-partner-with-securoak {
  padding: 10px;
  background-color: #f9fbfc;
  font-family: 'Inter', sans-serif;
}
}


@media (max-width: 320px) {
  .section-image {
    
    height: 250px;
    
    
  }
  .pam-section {
    padding: 10px;
  
  }
}


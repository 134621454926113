.offering-item:last-child {
    display: none;
  }
 
    .why-partner-image2 img {
      height: 238px;
      object-fit: cover;
      
  }
  .why-partner-features2 {
    margin-top: 20px;
}
.securoak-benefit {
 
  margin: 10px auto;
  font-size: 1.1rem;
  
}


@media (max-width: 320px) {
  .why-partner-image2 img {
   
   padding: 10px  ;
}
  
}

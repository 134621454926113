/* Disclaimer.css */

.disclaimer-container {
    padding: 40px 20px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
}

.disclaimer-content {
    max-width: 90%;
    margin-top: 37px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.disclaimer-title {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
}

.disclaimer-updated {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    margin-bottom: 1.5rem;
}

.disclaimer-section {
    margin-bottom: 1.5rem;
}

.disclaimer-section h4 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #444;
    margin-bottom: 0.5rem;
}

.disclaimer-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 0.8rem;
}

.disclaimer-section p:last-child {
    margin-bottom: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .disclaimer-container {
        padding: 20px 15px;
    }

    .disclaimer-content {
        padding: 20px;
    }

    .disclaimer-title {
        font-size: 1.8rem;
    }

    .disclaimer-section h4 {
        font-size: 1.1rem;
    }

    .disclaimer-section p {
        font-size: 0.95rem;
    }
}

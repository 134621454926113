/* Overall Section */
.staffing-outsourcing {
    background-color: #f1f3f5;
    padding: 50px 0;
    text-align: center;
  }
  
  .staffing-outsourcing .container {
    width: 90%;
    margin: 0 auto;
  }
 
  
  /* Service Description */
  .service-description p {
    font-size: 16px;
    line-height: 1.8;
    color: #34495e;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    
}
  .staffing-services-container {
   
    color: #333;
    background-color: #d6e7f7; /* Soft blue background */
    padding: 40px; /* Add some padding to give space around the content */
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
}
.Staffing-Services{
    font-size: 32px;
    color: #333;
}
.staffing-para{
    max-width: 800px;

}

.offering h3,p{
    
  
    margin: 0 auto;
    color: #333;
}
.staffing-sub-titles{
  text-align: center;
  
}
.offering h5{
    text-align: center;
    font-size: 18px;
}
.navigation{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
}
.offerings img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.staffing-content{
    max-width: 900px;
    margin: 0 auto;
    display: block;
  visibility: visible; 
}

.offering{
    padding: 30px ;
    background-color:#fff;
}
.cta{
    text-align: center;
    padding: 40px;
}
.cta p{
  text-align: center;
  max-width: 900px;
}






.why-partner-with-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    background-color: #f0f8ff; /* Light background for contrast */
  }
  
  .staff-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .content-left {
    flex: 1;
    padding-right: 30px;
    max-width: 50%; /* Ensures it's not too wide */
  }
  
  .content-left h2 {
    font-size: 32px;
    color: #333;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .content-left p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    display: flex;
    align-items: center; 
    text-align: center;
   
  }
  
  .content-right {
    flex: 1;
    max-width: 50%; /* Ensures image takes 50% of the container */
  }
  
  .content-right img {
    width: 100%;
    height: 350px;
    border-radius: 4px;
    object-fit: cover; /* Ensures image maintains aspect ratio */
  }
  .offering3{
    height: 230px;
  }

  .offering-image {
    transition: opacity 0.5s ease-in-out; /* Adjust the duration as per your requirement */
    opacity: 1; /* Make sure the image is fully visible by default */
  }
  
  .offering-image.hidden {
    opacity: 0; /* Hide the image before transitioning to the next one */
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .why-partner-with-us {
      flex-direction: column; /* Stacks the content vertically */
      text-align: center; /* Centers the text */
    }
  
    .content-left,
    .content-right {
      max-width: 100%; /* Each takes full width on smaller screens */
      padding-right: 0;
      padding-bottom: 20px; /* Adds space between text and image */
    }
  
    
  }
  .staffing-sub-para{
    max-width: 900px;
    text-align: center;
  }
 



  
  /* Responsive Design */
  @media (max-width: 768px) {
    .service-description p {
      font-size: 14px;
      text-align: left;
      padding: 0 20px;
    }
  }


  @media (max-width: 768px) {
    .staffing-sub-titles {
      
      font-size: 21px;
  }
  .offerings img {
   
    height: 200px;
    
}

  }
  @media (max-width: 425px) {
    .content-left p {
      
      text-align: justify;
  }
  .content-right img {
    height: 200px;
    object-fit: cover;
}
.staffing-sub-para {
    
  font-size: 14px;
}
.offering h3, p {
font-size: 15px;
}
.offering {
padding: 12px;

}
.cta {

padding: 10px;
}
.content-left h2 {
font-size: 27px;

}

  }
  @media (max-width: 375px) {

    .staffing-sub-para {
      font-size: 16px;
      text-align: center;
  }
  .offering h3, p {
   font-size: 18px;
}
.offering {
  padding: 10px;
 
}
.cta {
  
  padding: 10px;
}
.content-left h2 {
  font-size: 27px;
  
}
.staffing-services-container {
  
  padding: 12px;
  
}
.content-left p {
  font-size: 20px;
  
}
.offering3{
  height: 333px;
}
  }
  
  @media (max-width: 320px) {
    
    .next-btn2{
      margin-right: 46px !important;
    }
    .staffing-content {
     text-align: justify;
  }
  .Staffing-Services {
    font-size: 22px;
   
}
.staffing-sub-titles {
  font-size: 16px;
}
.staffing-services-container {
  
  padding: 12px;
  
}
.staffing-sub-para {
    
  font-size: 15px;
}
.navigation {
 
  margin-top: 53px;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  
  margin-right: 10px !important;
  margin-left:0 ;
  
}

 .prev-btn2{
  margin-left: -10px !important;
 }
.next-btn2{
  margin-left: 5px;
}
  }

  
  
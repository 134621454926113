.who-we-are {
  display: flex;
  justify-content: center;
  font-family: Inter, sans-serif;
  align-items: center;
  background-color: #f8f9fa;
  padding: 50px 70px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 20px;
    flex-wrap: wrap; // Ensure content wraps on smaller screens

    .text-content {
      flex: 1;
      padding-right: 30px; // Space between text and image
      // max-width: 50%; 
      h1 {
        font-size: 34px;
        line-height: 40px;
        color: #222;
        margin-bottom: 20px;
      }
      p {
        font-size:17px;
        line-height: 1.6;
        color: #555;
        margin-bottom: 20px;
      }
    }

    .image-container {
      flex: 1;
      // max-width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 30px;

      img {
        padding: 0;
        max-width: 100%;
        width: 100%;
        height: 450px;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }



















  
  // Responsive design for smaller screens
  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
      text-align: center;

      .text-content {
        max-width: 100%;
        padding-right: 0;
        margin-bottom: 30px;
      }

      .image-container {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 425px) {
  .who-we-are {
   
     padding: 0px 0px;
  }
 

}
@media (max-width: 375px) {
  .who-we-are .content-wrapper .text-content h1 {
 margin-top: 20px;
    
  }
  .who-we-are .content-wrapper .image-container img {
   
    height: 300px;
    width: 100%;
   
}
 

}

@media (max-width: 320px) {

  .who-we-are .content-wrapper .image-container img {
   
    height: 300px;
    width: 100%;
   
}
.who-we-are .content-wrapper .image-container {
 
  padding-left: 0px; 
}
.who-we-are .content-wrapper .text-content h1 {
  
  margin-top: 20px;
}

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.cloud_full {
  padding: 70px 0px 39px 0px;
  .cloud_cnt {
    .title {
        font-family: Inter, sans-serif;

      font-size: 24px;
      font-weight: 600;
      background-image: linear-gradient(
        to right top,
        #0e30ed,
        #004ff1,
        #0065ef,
        #0078e9,
        #2788e0
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
    .desc {
        font-family: Inter, sans-serif;
    position: relative;
    font-size: 29px;

    line-height: 33px;
    margin-top: -30px;
    margin-bottom: 29px;
    color: #222;
    }
//     .desc::before {
//     position: absolute;
//     content: "";
//     height: 2px;
//     width: 51px;
//     bottom: -7px;
//     left: 7%;
//     transform: translateX(-50%);
//     background: #0513f1;
// }

// .desc::after {
//     position: absolute;
//     content: "";
//     height: 9px;
//     width: 9px;
//     border-radius: 50%;
//     background: #fff;
//     border: 2px solid #0513f1;
//     box-shadow: 0 0 0 5px #fff;
//     bottom: -10px;
//     left: 1%;
//     transform: translateX(-50%);
// }
.partner-title{
  font-size: 16px;
  color: #222;
  text-align: left;
}
    .sub_desc {
    

      margin-top: 10px;
      color: #333;
      font-family: Inter, sans-serif;
      font-size: 16px;
      margin-bottom: 18px;
    
    }
  }
  .total_list {
    .list {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      .para {
        display: flex;
        align-items: baseline;
        gap: 10px;
        margin-top: 10px;
        padding: 9px;
        background-color: #fff; /* Optional: Card background color */
        border-radius: 8px; /* Optional: Rounded corners */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* Subtle shadow */
                    0 1px 3px rgba(0, 0, 0, 0.08); /* Lighter shadow */
                    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
        .tick {
          svg {
            color: #fff;
            background-color: #197fa1;
            height: 20px;
            width: 20px;
            border-radius: 20px;
            margin-bottom: 3px;
          }
        }
        .titles {
          color: #666;
          font-family: "Poppins", sans-serif;

          font-size: 15px;
    width: 13rem;
          border-radius: 10px;
        }
      }
    }
  }

  .learn {
    height: 40px;

    margin-top: 4px;
    border: none;
    border-radius: 30px;
    padding: 10px;
    width: 25%;
    background: linear-gradient(
      90deg,
      rgba(5, 17, 242, 1) 0%,
      rgba(6, 21, 241, 1) 30%,
      rgba(39, 136, 224, 1) 63%,
      rgba(49, 173, 219, 1) 87%,
      rgba(54, 187, 217, 1) 100%
    );
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 13px;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      color: #fff;
      .arrow_right {
        svg {
          color: #fff;
          font-size: 13px;
          margin-bottom: 3px;
        }
      }
    }
    &:hover {
      background: #fff; /* Change background on hover */
      color: #0059ff; /* Change text color on hover */
      border: 2px solid #0059ff; /* Add border on hover */

      a {
          color: #0059ff; /* Change link color on hover */
      }

      .arrow_right {
          svg {
              color: #0059ff; /* Change SVG color on hover */
          }
      }
  }
  }
  .image {
    overflow: hidden;
    position: relative;
    height: 600px;
    object-fit: cover;
       
    img {
      width: 100%;
      height: 570px;
      object-fit: cover;
      transition: transform 0.5s ease;
      // border-radius: 10px;
      // &:hover {
      //   transform: scale(1.1);
      // }
    }
  }
}
.partner-one  .partner-two .partner-three .partner-four{
  width: 150px;
}
.partner-1 img{
    width: 150px;
    height: 70px;
    object-fit: contain;
   
}
.partner-2 img{
  width: 150px;
  height: 70px;
    object-fit: contain;
}
.partner-3 img{
  width: 150px;
    height: 70px;
  object-fit: contain;
}
.partner-4 img{
  width: 150px;
    height: 70px;
  object-fit: contain;
}
.partner-5 img{
  width: 150px;
    height: 70px;
  object-fit: contain;
}
.partner-6 img,
.partner-7 img,
.partner-8 img,
.partner-9 img,
.partner-10 img{
  width: 150px;
    height: 70px;
  object-fit: contain;
}
.partner-7 img{
  width: 150px;
    height: 70px;
  object-fit: contain;
}

.partner-1 img,
.partner-2 img,
.partner-3 img,
.partner-4 img,
.partner-5 img,
.partner-6 img,
.partner-7 img,
.partner-8 img,
.partner-9 img,
.partner-10 img {
  width: 150px;
  height: 70px;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), 
  0 4px 6px rgba(0, 0, 0, 0.15);
  object-fit: contain;
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition */
}
.partner-1 img{
  padding: 15px;
}
.partner-1:hover img,
.partner-2:hover img,
.partner-3:hover img,
.partner-4:hover img,
.partner-5:hover img {
  transform: scale(1.1); /* Slight zoom effect */
  filter: brightness(1.2); /* Slight brightness increase */
}
.partner-logo{
  border-radius: 5px;
}
.para:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), 
              0 4px 6px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
  background-color: #f9f9f9; 
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .developement_full .api_management .desc {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .cloud_full .total_list .list .para .titles {
    font-size: 14px;
  }
  .cloud_full .total_list .list .para {
    gap: 5px;
  }
  .cloud_full .total_list .list .para .tick svg {
    height: 16px;
    width: 16px;
  }
  .cloud_full .learn {
    margin-top: 5px;
    height: auto;
    padding: 10px 20px;
    width: 25%;
    font-size: 12px;
  }
}
.partner-images{
  display: flex;
  flex-wrap: wrap;
}


@media (max-width: 375px) {
  
  .partner-images{
    margin-left: 50px;
    align-items: center;
    margin-left: 85px;
  }
  .image img{
    height: 150px;
    object-fit: cover;
   }
 


}

@media (max-width: 320px) {
  
  .partner-images{
    margin-left: 50px;
    align-items: center;
    margin-left: 50px;
  }
  .image img{
  height: 150px !important;
  object-fit: cover;
 }


}
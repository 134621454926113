
.noc-description {
    max-width: 900px;
    margin: 0 auto;
}
.noc-description p {
    font-size:15px;
    text-align: center;
    line-height: 1.8;
    color: #4a4a4a;
 
    padding: 20px;
    border-radius: 8px;
   
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Add hover effect */
  .noc-description p:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  


  .key-offerings-section {
    padding: 40px;
    background: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .key-offerings-title {
    font-size: 32px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .key-offerings-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: center; /* Center all items horizontally */
  }
  
  .offering-item {
 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .offering-item h3 i {
    font-size: 1.2rem;
    color: #2c7be5; /* Neutral color for the icons */
    transition: color 0.3s ease;
    padding-right: 10px;
  }
  
  .offering-item:hover h3 i {
    color: #2c7be5; /* Highlight icon color on hover */
  }
  .offering-item:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .offering-item h3 {
    font-size: 1.2rem;
    color: #2c7be5;
    margin-bottom: 10px;
  }
  
  .offering-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #4a4a4a;
  }
  
  /* Center the last card in its row */
  .offering-item:last-child {
    margin-left: auto;
    margin-right: auto;
    grid-column: span 2; /* Center card in a two-column layout */
  }
  .last-carditem{
    text-align: center;
  }
  .strongtitle{
    color: #2C7BE5 !important;
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    .key-offerings-list {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .offering-item:last-child {
      grid-column: span 2; /* Center last card on larger screens */
    }
  }
  @media (max-width: 425px) {

    .securoak-benefit p {
      font-size: 16px;
      
  }


  }
  @media (max-width: 375px) {

    .key-offerings-section {
      padding: 10px;
     
  }
  .noc-description p {
    font-size: 17px;
    
}
.offering-item p {
  font-size: 18px;
  
}

  }
  
  
  @media (max-width: 320px) {

    .benefit-image {
      width: 40px;
      height: 40px;
      
  }
  .benefit-text {
    font-size: 15px;
   
}
.section-title {
  font-size: 26px;
}
.offering-item h3 {
  font-size: 1rem;
  
}
.offering-item p {
  font-size: 15px;
  text-align: justify
}
.securoak-benefit p {
  font-size: 14px;
  
}
.section-title {
  font-size: 20px;
}
.noc-description p {
  font-size: 16px;
  
}
.offering-item p {
font-size: 16px;

}

  }

  


    .terms-container {
        padding: 40px 20px;
        background-color: #f9f9f9;
        display: flex;
        justify-content: center;
    }
    
    .terms-content {
        max-width: 90%;
        background-color: #ffffff;
        padding: 30px;
        margin-top: 37px;
        border-radius: 8px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    
    .terms-title {
        font-size: 2rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .terms-updated {
        font-size: 0.9rem;
        color: #666;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    
    .terms-section {
        margin-bottom: 1.5rem;
    }
    
    .terms-section h4 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #444;
        margin-bottom: 0.5rem;
    }
    
    .terms-section p {
        font-size: 1rem;
        line-height: 1.6;
        color: #555;
        margin-bottom: 0.8rem;
    }
    
    .terms-section ul {
        margin-top: 0.5rem;
        padding-left: 1.5rem;
    }
    
    .terms-section li {
        font-size: 1rem;
        color: #555;
        margin-bottom: 0.4rem;
    }
    
    /* Responsive Design */
    @media (max-width: 768px) {
        .terms-container {
            padding: 20px 15px;
        }
    
        .terms-content {
            padding: 20px;
        }
    
        .terms-title {
            font-size: 1.8rem;
        }
    
        .terms-section h4 {
            font-size: 1.1rem;
        }
    
        .terms-section p {
            font-size: 0.95rem;
        }
    }
    

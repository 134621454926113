.about {
   
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  
    margin-top: -4%;
    margin-bottom: 0%;
}
/* .container p{
    margin-bottom: -50px;
} */

.about-video {
    min-height: 300px;
}

.about-slider {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.about-slider .prevArrow,
.about-slider .nextArrow {
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%); */
   
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 0;
    line-height: 55px;
    text-align: center;
    background: #fff;
    transition: 0.2s ease;
}

/* .about-slider .prevArrow:focus,
.about-slider .nextArrow:focus {
    outline: 0;
} */

.about-slider .prevArrow:hover,
.about-slider .nextArrow:hover {
   background: #fff;
   color: #0059ff;
    
}

.about-slider .prevArrow {
    left: 25px;
}

.about-slider .nextArrow {
    right: 25px;
}

.abt-why-sec {
    background-image: url('../components/assests/banner-2.jpg ');
}

.inner-ser-sec {
    background-color: #fff;
    padding: 30px !important;
    
}
.inner-ser-sec h3 {
    background-color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 25px;
    margin-top: -1px;
    margin-bottom: 20px;
    color: #222;
    line-height: 33px;
}






.about_image img {
    width: 100%;
    height: 599px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 73px
}

.sec-start {
    margin-top: -5rem;
}

.ser-learn {
    text-decoration: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 30px;
    padding: 20px 10px;
    width: 30%;
    background: linear-gradient(90deg,
            rgba(5, 17, 242, 1) 0%,
            rgba(6, 21, 241, 1) 30%,
            rgba(39, 136, 224, 1) 63%,
            rgba(49, 173, 219, 1) 87%,
            rgba(54, 187, 217, 1) 100%);
    font-size: 13px;
    white-space: nowrap; /* Prevents text from wrapping */
}



.arrow-right {
    color: #fff;
    background-color: blue;
    padding: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: inline-block;
    /* Ensures proper sizing for icons */
    text-align: center;
    /* Center the icon if it's not aligned */
}

.inner-arrow-right {
    font-weight: 600;
    font-size: 1rem;
}

.section-li-border-half {
    position: relative;
    padding-left: 1rem;
    text-align: start;
}
/* .icon{
    border-radius: 50%;
    background: transparent;
   
    border: 1px solid;
    height: 5px;
    width: 5px;
    padding: 5px;
} */
.icon-design{
         width: 2px;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
}
.circle-logo i{
    background-color: white;
    color: rgb(0, 119, 255);
}
.circle-logo{
    font-size: 15px;
}

.container-double{
    text-align: start;
  

}
.section-li-border-half i{
    background-color: white;
    color: #0059ff;

}

.form-container h5{
   
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 23px;
    margin-top: -1px;
    margin-bottom: 38px;
    color: #fff;
    line-height: 33px;
    padding-top: 20px;
}

.form-details{
    background: linear-gradient(90deg,
            rgb(5, 88, 242) 0%,
            rgb(6, 69, 241) 30%,
            rgba(39, 136, 224, 1) 63%,
            rgba(49, 173, 219, 1) 87%,
            rgba(54, 187, 217, 1) 100%);
            border-radius: 10px;
           
          
            
           }
           .form-columns{
            flex: 0 0 auto;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
           }
.container-double p{
    margin-left: 16px;
}
.container-double span{
    margin-left: 16px;
}
.button{
   
    width: 20px;
    padding-left: 25px;
}
.form-container button{
    background:#fff;
    color: #007bff;
    border: 2px solid white;

}
.ser-learn2 {
    /* Your styles here */
    background-color: #fff;
    color: white;
    border: 2px solid;
}

/* toggle container */
.collapse {
    display: none; /* Hide all collapsible sections by default */
  }
  
  .collapse.show {
    display: block; /* Show the collapsible section when expanded */
  }
  
  .card-header {
    cursor: pointer; /* Indicate clickable area */
  }
  
  /* Font Awesome icon toggle */
  .card-header a i {
    margin-right: 8px; /* Add space  tween icon and text */
  }
  
  .card-header a.collapsed i {
    content: "\f067"; /* Plus icon */
  }
  
  .card-header a:not(.collapsed) i {
    content: "\f00d"; /* Minus icon */
  }
  .toggle-below-para{
    color: gray;
  }
  /* Default Plus Icon */
.card-header .fa-plus {
    content: "\f067"; /* Unicode for FontAwesome plus icon */
  }
  
  /* When the accordion is expanded (collapse is shown), change to Minus Icon */
  .collapse.show .card-header .fa-plus {
    content: "\f068"; /* Unicode for FontAwesome minus icon */
  }
  
  .btn-primary {
   
    border: 2px solid;
    padding: 10px 63px; /* Fixed padding */
    color: white; /* Text color */
    line-height: 1.5; /* Consistent line height */
    min-height: 40px; /* Minimum height */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
  }
  .btn-primary:hover{
    background: #fff;
    color: #0059ff;
   
  }
  .content-container{
    margin-bottom: 4%;
  }
  .section-title {
    
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 29px;
    margin-top: -1px;
    /* margin-bottom: 38px; */
    color: #222;
    line-height: 33px;
}
.section-paragraph{
    font-size: 15px;
    color: #000000e0;
    text-align: justify;
}
.container-double{
    font-size: 15px;
    color:  #000000e0;

}


.toggle-line{
    position: relative;
}

/* .toggle-line::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid#0513f1;
   bottom: -8px;
    left: 0;
}
.toggle-line::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 50px;
    left: 15px;
    bottom: -6px;
    background: #0513f1;
}   */

.toggle-line-form::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 50px;
    left: 15px;
    bottom: -6px;
    background: #fff !important;
}  


.toggle-line-form::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #fff !important;
   bottom: -8px;
    left: 0;
}

.toggle-card-title{
   
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 15px;
  
  
 
    
}

    .toggle-container-para {
        margin-top: 8px;
        font-size: 15px;
        color: #666;
        text-align: justify;
        font-family: "Poppins", sans-serif;
    }
.inner-form{
    border-radius: 5px;
    padding: 0 15px 2px;
    height: 40px;
    margin-bottom: 15px;
    border: 1px solid #e5e5e5;
   font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: #666;
}
.form-container-head1{
   
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 15px;
        color: #007bff;
    }
    .form-container{
        margin-bottom: 29px;
    }
    .keyfeatures-container {
        width: 100%;
        background-color: #fff;
        /* padding: 25px; */
     
    font-family: Inter, sans-serif;

    }
    .feature{
        text-align: start;
        margin-left: 10px;
    }
    /* .keyfeatures-container-points .container{
        text-align: start;
        gap: 10px;
        font-family: "Poppins", sans-serif;
        
    }
    */
    .keyfeatures-container-points .container span {
        display: inline-flex; /* Allows centering content */
        align-items: center; /* Centers the content vertically */
        font-size: 7px;
        font-weight: 900;
        justify-content: center; /* Centers the content horizontally */
        width: 15px; /* Set the width */
        height: 15px; /* Set the height equal to the width */
        border: 2px solid; 
        background-color: #f5f5f5;
        color: #007bff;
        border-radius: 50%; /* Makes it circular */
        padding: 0; /* Remove padding */
        text-align: center; /* Centers text within */
        margin: 5px;
    }
    .keyfeatures-container-points .container {
        text-align: start;
        gap: 10px;
       
    font-family: Inter, sans-serif;

        font-size: 15px;
    color: #666;
    margin-top: 20px;
    }
    
    .keyfeatures-container-points .row {
        display: flex; /* Use flexbox for alignment */
        flex-direction: column; /* Stack items vertically */
    }
    
    .point {
        display: flex; /* Use flexbox for each point */
        align-items: flex-start; /* Align items at the start */
        gap: 10px; /* Space between icon and text */
        margin-bottom: 10px; /* Space between points */
    }
    
    .point span {
        display: inline-flex; /* Center icon within span */
        align-items: center;
        justify-content: center;
        width: 30px; /* Set width for the icon container */
        height: 30px; /* Set height for the icon container */
        border: 2px solid; /* Keep existing styling */
        background-color: #f5f5f5;
        color: #007bff;
        border-radius: 50%; /* Make it circular */
    }
    
#question{
    height: 75px;
}
.form-container1{
    margin-top: -1rem;
}
.benner-icon{
  font-size: 10px;
  margin: 5px;
}
.form-input-padding{
    padding-left: 24px;
    padding-right: 24px;
}
@media only screen and (max-width: 360px) {
   .keyfeatures-container-points .container {
        text-align: start;
        gap: 10px;
     
    font-family: Inter, sans-serif;

      font-size: 10px;
        color: #666;
        margin-top: 20px;
    }.section-paragraph {
        font-size: 10px;
        color: #666;
        text-align: justify;
    }.circle-logo {
        font-size: 9px;
    }p {
        font-size: 8px;
        color: #666;
    }
    .container-double p {
        margin-left: 10px;
    }.btn-primary {
        border: 2px solid;
        padding: 5px 40px;
        color: white;
        line-height: 1.5;
        min-height: 38px;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        width: 10%;
    }.about {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 12%;
        margin-bottom: 0%;
    }.inner-ser-sec h3 {
        background-color: #fff;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 13px;
        margin-top: -1px;
        margin-bottom: 14px;
        color: #222;
        line-height: 19px;
    }.benner-icon {
        font-size: 6px;
        margin: 5px;
    }.keyfeatures-container-points .container {
        text-align: start;
        gap: 10px;
        font-family: Inter, sans-serif;
      font-size: 10px;
        color: #666;
        margin-top: 20px;
    }.section-paragraph {
        font-size: 10px;
        color: #666;
        text-align: justify;
    }.circle-logo {
        font-size: 9px;
    }p {
        font-size: 8px;
        color: #666;
    }.container-double p {
        margin-left: 10px;
    }.btn-primary {
        border: 2px solid;
        padding: 5px 40px;
        color: white;
        line-height: 1.5;
        min-height: 38px;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        width: 10%;
    }.ser-learn {
        text-decoration: none;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 30px;
        padding: 20px 10px;
        width: 39%;
       
        font-size: 9px;
       
    }.keyfeatures-container-points .container span {
       
        width: 18px;
        height: 14px;
       
    }.section-title {
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 16px;
        margin-top: -1px;
        margin-bottom: 38px;
        color: #222;
        line-height: 19px;
    } .form-container-head1 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 14px;
       
    }.toggle-line::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 39px;
        left: 15px;
        bottom: -6px;
        background: #0513f1;
    }.toggle-line::before {
       
        height: 8px;
        width: 8px;
      
    }.toggle-card-title {
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 10px;
    }.toggle-container-para {
        margin-top: -4px;
        font-size: 9px;
       
    }.form-container h5 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 14px;
        margin-top: 3px;
        margin-bottom: 38px;
        color: #fff;
        line-height: 19px;
        padding-top: 19px;
    }.inner-form {
        border-radius: 5px;
        padding: 2px 15px 2px;
        height: 28px;
        margin-bottom: 15px;
       
        font-weight: 400;
      
        font-size: 11px;
       
    }.btn-primary {
        border: 2px solid;
        padding: 11px 97px;
        color: white;
        line-height: 0.5;
        min-height: 8px;
        transition: background-color 0.3s ease, border-color 0.3s ease;
    }.btn {
        font-size: 13px;
       
        padding: 16px 44px;
      
    }.ser-learn2 {
        text-decoration: none;
        height: 36px;
        margin-top: 10px;
       width: 25%;
       padding: 20px 10px;
      
        font-size: 13px;
       
       
     
    }.form-container button {
        background: #fff;
        color: #007bff;
        border: 2px solid white;
        padding: 12px 55px;
        font-size: 10px;
    }




}






.identify-cards{
    justify-content: space-around;
}

.content-section {
    padding: 60px 0;
    background-color: #fff;
  
    .content-container {
      text-align: center;
    }
  
    .section-header {
      margin-bottom: 30px;
    }
  
    .toggle-line {
      font-size: 2.5rem;
      font-weight: 700;
      color: #0613f2;
      line-height: 1.3;
      margin-bottom: 20px;
    }
  
    .section-description {
        max-width: 80%; 
        margin: 0 auto; 
        text-align: center; 
        font-size: 1rem;
        line-height: 1.6; 
        padding: 10px 0; 
    }
  
    .keyfeatures-container {
        text-align: left;
        margin-bottom: -33px;
    }
  
    .feature-heading {
      font-size: 1.6rem;
      font-weight: 600;
      color: #333;
      margin-bottom: 20px;
    }
  
    .features-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
      .feature-point {
        margin-bottom: 20px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 45%;
        text-align: left;
        transition: all 0.3s ease-in-out;
  
        .feature-icon {
        
          color: #007bff;
        }
  
        .feature-text {
          font-size: 1rem;
          color: #444;
  
          strong {
            color:#333;
          }
        }
  
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
        }
      }
    }
  
    .about-image {
      text-align: center;
     
    }
    .keyfeatureimg {
        /* max-width: 100%; */
        height: 600px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
  
   
  }
  



  /* journey section */

  .journey-section {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: center;
}

.journey-section h2 {
    font-size: 2.5em;
    color: #2b2b2b;
    margin-bottom: 20px;
}

.journey-section p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
}

.steps-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.step-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: left;
}

.step-card:hover {
    transform: translateY(-10px);
}

.step-icon {
    font-size: 2.5em;
    color: #007bff;
    margin-bottom: 15px;
}

.step-card h3 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 10px;
}

.step-card p {
    font-size: 1em;
    color: #666;
    line-height: 1.5;
}






.unlocking-digital-section {
    background-color: #f9f9f9;
    padding: 22px 8px;
    max-width: 950px;
    margin: 30px auto;
    text-align: center;
    border-radius: 15px;
    margin-bottom: 63px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .unlocking-digital-title {
    font-size: 27px;

    color: #333;
    margin-bottom: 20px;
    
    letter-spacing: 1px;
  }
  
  .unlocking-digital-description {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6;
    max-width: 750px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: left;
  }
  
  .unlocking-digital-section:hover {
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }
  
  .unlocking-digital-description::before {
    content: "🔑";
    margin-right: 10px;
    color: #fbb034;
    font-size: 1.5em;
  }
  

 
  @media (max-width: 1024px) {  
    .feature-text {
        font-size: 16px !important;
        
    }


  }
  
  @media (max-width:768px) {  
    .features-list {
        flex-direction: column;
        align-items: center;
  
        .feature-point {
          width: 80%;
        }
      }
      .feature-point {
       
         width: 100% !important; 
       
    }
    .identity-page .identity-image-container .overlay-text h1 {
        font-size: 20px;
        
    }
    
  }

  @media (max-width: 425px)     {
    .feature-point {
       
        width: 100% ; 
      
   }
   .keyfeatureimg {
  
    height: 300px;
   
}
.identity-page .content-section p {
    font-size: 17px;
    text-align: center;
}
.section-description {
    max-width: 100%;
    
}


  }

  @media (max-width: 375px) {  
    .feature-text {
        font-size: 20px !important;
    }
    .section-description {
        max-width: 100% !important;
       width: 100%;
       
    }
    .identity-page .content-section p {
        font-size: 20px;
        text-align: center;
    }
    .identity-page .content-section p {
        font-size: 19px;
        text-align: center;
    }
    .keyfeatures-container {
       
        padding: 4px;
       
    }
    .keyfeatureimg {
        margin-top: 20px;
     }
     .keyfeatureimg {
       height: 300px !important;
     }
     .feature {
       text-align: center;
    }
  }

  @media (max-width: 320px) {  
    .identity-page .content-section {
        max-width: 100%;
        padding: 45px 5px 8px 5px;
    }
    .feature-text {
        font-size: 17px !important;
    }
    .identity-page .content-section p {
        font-size: 18px;
        text-align: center;
    }
    .section-description {
        max-width: 100%;
       width: 100%;
    }
    .keyfeatures-container {
       
        padding: 4px;
       
    }
    .keyfeatureimg {
       margin-top: 10px;
    }
  }